<template>
  <div class="popup_wrap" style="width:750px; height:600px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- container_ekmtc -->
      <h1 class="page_title">{{ $t('tit.CSBL400.001') }}</h1>
      <div class="content_box">
        <h2 class="content_title">{{ $t('msg.SETT060P040.001') }}</h2>  <!-- 1.요청내용 및 알림설정 -->
        <table class="tbl_row">
          <colgroup>
            <col width="18%"><col width="82%">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.ONEX010P070.009') }}</th>
              <td>{{ param.blNo }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSBL270.033') }}</th> <!-- 요청자 -->
              <td>{{ list.rstStr }}</td>
            </tr>
            <tr>
              <th>{{ $t('tit.CSBL400.005') }}</th> <!-- 요청내용 -->
              <td><textarea v-model="reqParams.reqCont"></textarea></td>
            </tr>
            <tr>
              <th>{{ $t('msg.SETT060P040.002') }}</th> <!-- 자동알림서비스 요청 -->
              <td>
                <p class="mt5">
                  <a class="button sm" @click="autoArmService()">
                    [My Information] – {{ $t('msg.SETT060P040.002') }} <!-- 자동알림서비스 요청 -->
                  </a>
                </p>
                <p>{{ $t('msg.SETT060P040.003') }}</p> <!-- 해당 요청건의 승인 결과를 원하시는 매체를 선택하여 받아 보실 수 있습니다. -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="content_box mt10">
        <h2 class="content_title">2. {{ $t('tit.CSBL400.006') }}</h2>  <!-- 컨테이너 합계 -->
        <table class="tbl_col">
          <colgroup>
            <col style="width:14%">
            <col style="width:14%">
            <col style="width:12%">
            <col style="width:12%">
            <col style="width:12%">
            <col style="width:12%">
            <col style="width:12%">
            <col style="width:12%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col" rowspan="2">Size</th>
              <th scope="col" rowspan="2">Type</th>
              <th scope="colgroup" :colspan="6">{{ $t('msg.SETT060P040.016') }}</th> <!-- 발생일수 다국어수정필요 WMH-->
            </tr>
            <tr>
              <th class="border_left" scope="col">DEM</th>
              <th scope="col">DET</th>
              <th scope="col">DET (combined)</th>
              <th scope="col">MOR</th>
              <th scope="col">STR</th>
              <th scope="col">OFC</th>
            </tr>
          </thead>
          <tbody v-if="cntrList">
            <tr v-for="(item, idx) in cntrList" :key="idx">
              <td>{{ item.cntrSzCd }}</td>
              <td :id="'cntrTypCd_' + idx">{{ item.cntrTypCd }}</td>
              <td :id="'dem' + idx">{{ item.demMax }}</td>
              <td :id="'det' + idx">{{ item.detMax }}</td>
              <td :id="'cmb' + idx">{{ item.cmbMax }}</td>
              <td :id="'mor' + idx">{{ item.morMax }}</td>
              <td :id="'str' + idx">{{ item.strMax }}</td>
              <td :id="'ofc' + idx">{{ item.ofcMax }}</td>
            </tr>
          </tbody>
          <!-- <tbody v-if="cntrInList && param.eiCatCd === 'I'">
            <tr v-for="(item, idx) in cntrInList" :key="idx">
              <td>{{ item.cntrSzCd }}</td>
              <td :id="'cntrTypCd_' + idx">{{ item.cntrTypCd }}</td>
              <td :id="'dem' + idx">{{ item.demMax }}</td>
              <td :id="'det' + idx">{{ item.detMax }}</td>
              <td :id="'cmb' + idx">{{ item.cmbMax }}</td>
              <td :id="'mor' + idx">{{ item.morMax }}</td>
              <td :id="'str' + idx">{{ item.strMax }}</td>
            </tr>
          </tbody> -->
          <tbody v-else>
            <tr v-for="(item, idx) in rstList" :key="idx">
              <td>{{ item.cntrSzCd }}</td>
              <td>{{ item.cntrTypCd }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <template v-if="auth.serviceLang !== 'KOR'">
          <p>
            <br />
            The numbers indicate the total number of days occurred at the POL/POD regardless of freetime.
          </p>
        </template>
        <template v-if="auth.serviceLang === 'KOR'">
          <p>
            <br />
            표시된 숫자는 Freetime과 관계없이 선적지/양하지에서 발생한 총 경과일수를 의미합니다.
          </p>
        </template>
        <h2 class="content_title">3. {{ $t('msg.CSBL400.021') }}</h2>
        <div class="desc_list base">
          <ul>
            <li class="mt5">{{ $t('msg.CSBL400.013') }}</li>
          </ul>
        </div>
        <br>
        <!-- 선적지 -->
        <div id="load" class="load">
          <input type="checkbox" id="chk1"
                 true-value="Y"
                 false-value="N"
                 v-model="loading"
                 @change.prevent="changeInOut('loading', loading)"
          >
          <label for="chk1"><span style="color:#075bb9"><b>{{ $t('msg.SETT060P040.004') }} </b></span>: {{ polPortEnm }} </label>
          <table class="tbl_col" v-show="loading==='Y'">
            <colgroup>
              <col style="width:16%">
              <col style="width:16%">
              <col style="width:16%">
              <col style="width:16%">
              <col style="width:16%">
              <col style="width:16%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">DEM</th>
                <th scope="col">DET</th>
                <th scope="col">DET (combined) </th>
                <th scope="col">MOR</th>
                <th scope="col">OFC</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(vo, idx) in outAppRequestList" :key="'tr_' + idx">
                <td>{{ vo.cntrTypCd }}</td>
                <td>
                  <e-input-number
                    v-model="vo.outDem"
                    :id="'dem_out_'+vo.cntrTypCd"
                    :disabled="vo.dem == 0 || checkDem"
                    @input="setOutFreetimeReqList(vo.outDem, '01', 'O', vo.cntrTypCd, 'dem' + idx, rstList.polCtrCd)"
                  ></e-input-number>
                </td>
                <td>
                  <e-input-number
                    v-model="vo.outDet"
                    :id="'det_out_'+vo.cntrTypCd"
                    :disabled="vo.det == 0 || checkDet"
                    @input="setOutFreetimeReqList(vo.outDet, '02', 'O', vo.cntrTypCd, 'det' + idx, rstList.polCtrCd)"
                  ></e-input-number>
                </td>
                <td>
                  <e-input-number
                    v-model="vo.outCmb"
                    :id="'cmb_out_'+vo.cntrTypCd"
                    :disabled="vo.comb == 0"
                    @input="setOutFreetimeReqList(vo.outCmb, '03', 'O', vo.cntrTypCd, 'comb' + idx, rstList.polCtrCd)"
                  ></e-input-number>
                </td>
                <td>
                  <e-input-number
                    v-model="vo.outMor"
                    :id="'mor_out_'+vo.cntrTypCd"
                    :disabled="vo.mor == 0 || checkMor"
                    @input="setOutFreetimeReqList(vo.outMor, '04', 'O', vo.cntrTypCd, 'mor' + idx, rstList.polCtrCd)"
                  ></e-input-number>
                </td>
                <td>
                  <e-input-number
                    v-model="vo.outOfc"
                    :id="'ofc_out_'+vo.cntrTypCd"
                    :disabled="vo.ofc == 0"
                    @input="setOutFreetimeReqList(vo.outOfc, '14', 'O', vo.cntrTypCd, 'ofc' + idx, rstList.polCtrCd)"
                  ></e-input-number>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <br>
        <!-- 도착지 -->
        <div id="dest" class="dest">
          <input type="checkbox" id="chk2"
                 true-value="Y"
                 false-value="N"
                 v-model="destination"
                 @change.prevent="changeInOut('destination', destination)"
          >
          <label for="chk2"><span style="color:#075bb9"><b>{{ $t('msg.SETT060P040.005') }} </b></span>: {{ podPortEnm }} </label>
          <table class="tbl_col" v-show="destination==='Y'">
            <colgroup>
              <col style="width:16%">
              <col style="width:16%">
              <col style="width:16%">
              <col style="width:16%">
              <col style="width:16%">
              <col style="width:16%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">TYPE</th>
                <th scope="col">DEM</th>
                <th scope="col">DET</th>
                <th scope="col">DET (combined) </th>
                <th scope="col">MOR</th>
                <th scope="col">OFC</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(vo, idx) in inAppRequestList" :key="'tr_' + idx">
                <td>{{ vo.cntrTypCd }}</td>
                <td>
                  <e-input-number
                    v-model="vo.inDem"
                    :id="'dem_out_'+vo.cntrTypCd"
                    :disabled="vo.dem == 0 || podCtrCd == 'KR'"
                    @input="setInFreetimeReqList(vo.inDem, '01', 'I', vo.cntrTypCd, 'dem' + idx, rstList.polCtrCd)"
                  ></e-input-number>
                </td>
                <td>
                  <e-input-number
                    v-model="vo.inDet"
                    :id="'det_out_'+vo.cntrTypCd"
                    :disabled="vo.det == 0 || podCtrCd == 'KR'"
                    @input="setInFreetimeReqList(vo.inDet, '02', 'I', vo.cntrTypCd, 'det' + idx, rstList.polCtrCd)"
                  ></e-input-number>
                </td>
                <td>
                  <e-input-number
                    v-model="vo.inCmb"
                    :id="'cmb_out_'+vo.cntrTypCd"
                    :disabled="vo.comb == 0"
                    @input="setInFreetimeReqList(vo.inCmb, '03', 'I', vo.cntrTypCd, 'comb' + idx, rstList.polCtrCd)"
                  ></e-input-number>
                </td>
                <td>
                  <e-input-number
                    v-model="vo.inMor"
                    :id="'mor_out_'+vo.cntrTypCd"
                    :disabled="vo.mor == 0"
                    @input="setInFreetimeReqList(vo.inMor, '04', 'I', vo.cntrTypCd, 'mor' + idx, rstList.polCtrCd)"
                  ></e-input-number>
                </td>
                <td>
                  <e-input-number
                    v-model="vo.inOfc"
                    :id="'ofc_out_'+vo.cntrTypCd"
                    :disabled="vo.ofc == 0 || freetimeExpImpRtnSacYn.rcvOfcImpYn === 'Y'"
                    @input="setInFreetimeReqList(vo.inOfc, '14', 'I', vo.cntrTypCd, 'ofc' + idx, rstList.polCtrCd)"
                  ></e-input-number>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p class="mt5 ml20"><a class="button sm" @click="openPopup('containerCheckPop')">{{ $t('msg.SETT060P040.006') }}</a></p>

      <div class="mt10 text_center">
        <a class="button blue lg" @click="insertFreetime()">{{ $t('msg.SETT060P040.007') }}</a>
        <a class="button gray lg" @click="$emit('close')">{{ $t('msg.SETT060P040.008') }}</a>
      </div>
      <div id="layerPop">
        <win-layer-pop>
          <component
            v-if="customComponent"
            :is="customComponent"
            @close="closePopup"
            @dupOk="dupCheckPopup"
            :parent-info="popupParams"
          />
        </win-layer-pop>
      </div>
    </div>
  </div>
</template>

<script>
import FreeTimeExtensReq from '@/api/rest/settle/freeTimeExtensReq'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import WinLayerPopWrap from '@/pages/trans/popup/WinLayerPopWrap'
import ContainerCheckPop from '@/pages/settle/ContainerCheckPop'
import FreeTimeExtensReqDupCheckPop from '@/pages/settle/popup/FreeTimeExtensReqDupCheckPop'
import EInputNumber from '@/components/common/EInputNumber'
import { rootComputed } from '@/store/helpers'
export default {
  name: 'FreeTimeExtensReq',
  components: {
      WinLayerPop,
      WinLayerPopWrap,
      ContainerCheckPop,
      FreeTimeExtensReqDupCheckPop,
      EInputNumber
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          eiCatCd: '',
          sr: '',
          demDetCatCd: ''
        }
      }
    },
    freetimeExpImpRtnSacYn: {
      type: Object,
      default: function () {
        return {
          impRtnYn: '',
          rcvDecExpYn: '',
          rcvDecImpYn: '',
          rcvDemExpYn: '',
          rcvDemImpYn: '',
          rcvDetExpYn: '',
          rcvDetImpYn: '',
          rcvOfcImpYn: '',
          rcvMorExpYn: ''
        }
      }
    }
  },
  data: function () {
    return {
      param: {
        blNo: this.parentInfo.blNo,
        eiCatCd: this.parentInfo.eiCatCd,
        sr: this.parentInfo.sr,
        demDetCatCd: this.parentInfo.demDetCatCd,
        freeTimeInvoice: this.parentInfo.freeTimeInvoice
      },
      reqParams: {
        blNo: this.parentInfo.blNo,
        reqCont: this.$t('tit.CSBL400.013'),
        smsSntYn: 'N',
        emlSntYn: 'N',
        reqUsrNm: ' ',
        emlAddr: ' ',
        talkPicNo: ' ',
        talkPicNm: ' ',
        talkMbpNo: ' ',
        dupCheck: 'N',
        apvCheck: 'N',
        mbpBsnNo: ' ',
        mbpOfcNo: ' ',
        freeTimeReqList: []
      },
      list: [
        //rstList: {}
      ],
      rstList: {

      },
      send: {
        reqCont: '요청 내용',
        picNm: '',
        applicationRequestList: [{
          asDemDetCntrType: 'GP',
          asDemDetCatCd: '',
          asEiCatCd: '',
          payPlcCd: 'INC',
          reqDds: ''
        }]
      },
      demCtrCd: '',
      demPortNm: '',
      aeOverYn: '',

      polPortEnm: '',
      podPortEnm: '',
      podCtrCd: '',
      polPortNm: '',
      podPortNm: '',

      loading: '',
      destination: '',

      inAppRequestList: null,
      outAppRequestList: null,
      inAppRequestListCnt: null,
      outAppRequestListCnt: null,
      colspan: 5,

      customComponent: null,
      popupParams: {},

      outTempList: [],
      inTempList: [],

      // 중복요청
      dupList: [],
      dupResultType: '',

      // 중복요청
      apvList: [],
      apvResultType: '',
      apvstr: '',
      reqApvList: [],

      insResultList: [],
      insResultType: '',
      // 컨테이너 합계
      cntrList: [],
      cntrOutList: [],
      cntrInList: [],
      checkDem: false,
      checkDet: false,
      checkMor: false,
      checkSearh: false,
      morCnt: 0
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    if (this.parentInfo.eiCatCd === 'O') { // 수출일때 선적지 체크
      this.loading = 'Y'
      this.searchData()
    } else {
      this.destination = 'Y'
      this.searchData()
    }
  },
  mounted () {
    // 수출,수입에 따라 선적지,도착지 순서 조정
    if (this.parentInfo.eiCatCd === 'I') {
        $('.load').insertAfter('.dest')
    }
  },
  methods: {
    setOutFreetimeReqList (value, demDetCatCd, eiCatCd, cntrTypCd, guId, ctrCd) {
      // 중복데이터 삭제
      for (var i = 0; i < this.reqParams.freeTimeReqList.length; i++) {
        if (this.reqParams.freeTimeReqList[i].guId === guId &&
            this.reqParams.freeTimeReqList[i].eiCatCd === eiCatCd) {
            this.reqParams.freeTimeReqList.splice(i, 1)
        }
      }

      // freetime 요청 데이터배열
      this.reqParams.freeTimeReqList.push(
        {
          cntrTypCd: cntrTypCd,
          blNo: this.param.blNo,
          demDetCatCd: demDetCatCd,
          eiCatCd: eiCatCd,
          reqDds: value,
          payPlcCd: this.polPortNm,
          guId: guId,
          ctrCd: ctrCd,
          ldCatCd: eiCatCd === 'O' ? 'L' : 'D'
        }
      )
    },
    setInFreetimeReqList (value, demDetCatCd, eiCatCd, cntrTypCd, guId, ctrCd) {
      // 중복데이터 삭제
      for (var i = 0; i < this.reqParams.freeTimeReqList.length; i++) {
        if (this.reqParams.freeTimeReqList[i].guId === guId &&
            this.reqParams.freeTimeReqList[i].eiCatCd === eiCatCd) {
            this.reqParams.freeTimeReqList.splice(i, 1)
        }
      }

      // freetime 요청 데이터배열
      this.reqParams.freeTimeReqList.push(
        {
          cntrTypCd: cntrTypCd,
          blNo: this.param.blNo,
          demDetCatCd: demDetCatCd,
          eiCatCd: eiCatCd,
          reqDds: value,
          payPlcCd: this.podPortNm,
          guId: guId,
          ctrCd: ctrCd,
          ldCatCd: eiCatCd === 'O' ? 'L' : 'D'
        }
      )
    },
    // 선적지 freeDay빈값 체크
    checkOutReq () {
      let isOk = true
      let msg = ''

      const inputEl = document.querySelectorAll('input[name="out"]')

      inputEl.forEach(el => {
        if (el.disabled === false && this.loading === 'Y') {
           if (this.isEmpty(el.value)) {
             isOk = false
             msg = this.$t('msg.SETT060P040.011')
           }
        }
      })

      if (!isOk) {
        this.openAlert(msg)
      }

      return isOk
    },
    // 양하지 freeDay빈값 체크
    checkInReq () {
      let isOk = true
      let msg = ''

      const inputEl = document.querySelectorAll('input[name="in"]')

      inputEl.forEach(el => {
        if (el.disabled === false && this.destination === 'Y') {
           if (this.isEmpty(el.value)) {
             isOk = false
             msg = this.$t('msg.SETT060P040.011')
           }
        }
      })

      if (!isOk) {
        this.openAlert(msg)
      }

      return isOk
    },
    // freetime 연장요청 일수 빈값 모두 체크
    checkInOutReq () {
      let isAllOk = true

      if (!this.checkOutReq()) {
        isAllOk = false
      }

      if (!this.checkInReq()) {
        isAllOk = false
      }

      return isAllOk
    },
    // DEM, DET, DEC, OFC 정산완료 여부 체크
    checkDemDetRcv () {
      let isRcvChk = false

      for (var i = 0; i < this.reqParams.freeTimeReqList.length; i++) {
          if (this.reqParams.freeTimeReqList[i].reqDds != null) {
            if (this.reqParams.freeTimeReqList[i].demDetCatCd === '01') {
              if (this.freetimeExpImpRtnSacYn.rcvDemExpYn === 'Y' || this.freetimeExpImpRtnSacYn.rcvDemImpYn === 'Y') {
                isRcvChk = true
                break
              }
            } else if (this.reqParams.freeTimeReqList[i].demDetCatCd === '02') {
              if (this.freetimeExpImpRtnSacYn.rcvDetExpYn === 'Y' || this.freetimeExpImpRtnSacYn.rcvDetImpYn === 'Y') {
                isRcvChk = true
                break
              }
            } else if (this.reqParams.freeTimeReqList[i].demDetCatCd === '03') {
              if (this.freetimeExpImpRtnSacYn.rcvDecExpYn === 'Y' || this.freetimeExpImpRtnSacYn.rcvDecImpYn === 'Y') {
                isRcvChk = true
                break
              }
            } else if (this.reqParams.freeTimeReqList[i].demDetCatCd === '14') {
              if (this.freetimeExpImpRtnSacYn.rcvOfcImpYn === 'Y') {
                isRcvChk = true
                break
              }
            }
          }
      }
      return isRcvChk
    },
    // DEM, DET, MOR 정산완료 여부 체크
    checkDemDetMorRcv (demDetCatCd) {
      let isRcvChk = false

      if (demDetCatCd === '01') {
        if (this.freetimeExpImpRtnSacYn.rcvDemExpYn === 'Y' || this.freetimeExpImpRtnSacYn.rcvDemImpYn === 'Y') {
          isRcvChk = true
        }
      } else if (demDetCatCd === '02') {
        if (this.freetimeExpImpRtnSacYn.rcvDetExpYn === 'Y' || this.freetimeExpImpRtnSacYn.rcvDetImpYn === 'Y') {
          isRcvChk = true
        }
      } else if (demDetCatCd === '03') {
        if (this.freetimeExpImpRtnSacYn.rcvDecExpYn === 'Y' || this.freetimeExpImpRtnSacYn.rcvDecImpYn === 'Y') {
          isRcvChk = true
        }
      } else if (demDetCatCd === '04') {
        if (this.freetimeExpImpRtnSacYn.rcvMorExpYn === 'Y') {
          isRcvChk = true
        }
      }

      return isRcvChk
    },
    // freetime 연장요청 신청
    async insertFreetime () {
      // let isInOutChk = this.checkInOutReq()

      // 입력하지 않은 input box 값인 경우 해당 배열 삭제하여 조회 안도도록 함
      // @input에서 하드코딩으로 호출하기에 삭제함
      for (var i = 0; i < this.reqParams.freeTimeReqList.length; i++) {
        if (this.reqParams.freeTimeReqList[i].reqDds === '') {
          this.reqParams.freeTimeReqList.splice(i, 1)
        }
        //console.log('this.reqParams.freeTimeReqList.length', this.reqParams.freeTimeReqList.length)
      }

      if (this.reqParams.freeTimeReqList.length === 0) {
        this.openAlert(this.$t('msg.SETT060P040.011'))
        return
      }

      let isOk = true
      let chkCon = this.compareFreeDay()
      let isRcvChk = this.checkDemDetRcv()

      if (isRcvChk) {
        this.openAlert(this.$t('msg.SETT060G010.060'))
        return
      }

      if (isOk && chkCon) {
        await FreeTimeExtensReq.insertRequestFreetime(this.reqParams)
        .then(response => {
          // console.log('response.data=' + JSON.stringify(response))

          // this.insResultList = response.data.freetimeReqList
          this.insResultType = response.data.resultType

          // 승인 내역 조회
          if (response.data.resultTypeA === 'apvY') {
              this.apvList = response.data.apvList
              console.log('searchApvData apvY >>> ', this.apvList)

              // 승인 된 데이터 가져오기
              //this.searchApvData()

              if (this.$ekmtcCommon.isNotEmpty(this.apvList) && this.apvList.length > 0) {
                let msgReqDds = []
                let msgRcmdDds = []

                this.apvList.forEach(apv => {
                  let demDetNm = ''
                  msgReqDds.push(apv.reqDds)

                  switch (apv.demDetCatCd) {
                    case '01' :
                      demDetNm = 'DEM'
                    break

                    case '02' :
                      demDetNm = 'DET'
                    break

                    case '03' :
                      demDetNm = 'DET(Combined)'
                    break

                    case '04' :
                      demDetNm = 'MOR'
                    break

                    case '14' :
                      demDetNm = 'OFC'
                    break
                  }

                  msgRcmdDds.push(demDetNm + ' : ' + apv.rcmdDds + this.$t('msg.ONEX020P061.014')) // 일
                })

                // console.log(msgRcmdDds)
                // let msg = this.$t('msg.SETT060P040.015') + ' ' + msgReqDds.join(', ') + this.$t('msg.SETT060P040.014')
                let msg = this.$t('msg.SETT060P040.017') + ' (' + msgRcmdDds.join('/ ') + ')' + this.$t('msg.SETT060P040.018') +
                          '\n' + this.$t('msg.SETT060P040.021') + '\n' + this.$t('msg.SETT060P040.022')

                this.$ekmtcCommon.alertDefault(msg)
              }
          } else {
            if (isRcvChk) {
              this.openAlert(this.$t('msg.SETT060G010.060'))
              return
            }

            if (response.data.resultTypeD === 'dupY') {
                // console.log('response.data.resultTypeD ==> ', response.data.resultTypeD)
                // console.log('searchApvData dupY')
                this.dupList = response.data.dupList
                this.openPopup('freeTimeExtensReqDupCheckPop')
            } else {
                // freetime 요청 성공 후 freetime요청화면 재조회위해 값 변경
                this.$store.commit('settle/updateIsRefreshFreetimeReq', true)
                this.openAlert(this.$t('msg.SETT060P040.009'))
                this.$emit('close')
            }
          }
        }).catch(err => {
          this.openAlert(this.$t('msg.SETT060P040.010'))
          console.log(err)
        }).finally(() => {
          // 데이터 초기화
          this.reqParams.dupCheck = 'N'
          this.reqParams.apvCheck = 'N'
        })
      }
    },
    openPopup (compNm) {
      this.customComponent = compNm

      if (compNm === 'containerCheckPop') {
        this.popupParams = {
          blNo: this.param.blNo
        }
          // console.log('this.popupParams: ', this.popupParams)
      } else if (compNm === 'freeTimeExtensReqDupCheckPop') {
        this.popupParams = {
          dupList: this.dupList
        }
      } else {
        this.popupParams = {}
      }

      $('#layerPop > .popup_dim').fadeIn()
      $('.popup_dim').css('overflow', 'hidden')
    },
    // 연장요청 중복 체크 팝업
    dupCheckPopup () {
       this.reqParams.dupCheck = 'Y'

       this.insertFreetime(this.reqParams)

       this.closePopup()
    },
    closePopup () {
      this.customComponent = null

      $('#layerPop > .popup_dim').fadeOut()
      $('body,html').removeAttr('style')
    },
    changeInOut (gubun, value) {
      if (value === 'Y') {
        if (gubun === 'loading') {
          this.param.eiCatCd = 'O'
          this.searchData()
        } else if (gubun === 'destination') {
          this.param.eiCatCd = 'I'
          this.searchData()
        }
      } else {
        // if (gubun === 'loading') {
        //   this.outAppRequestList = []
        // } else if (gubun === 'destination') {
        //   this.inAppRequestList = []
        // }
      }
    },
    // freetime 연장요청 일수 비교 체크
    compareFreeDay () {
      let isOk = true
      let sMsgCd = ''
      let msgDay = ''
      let msg = ''
      let demDetCatDayList = this.cntrList

      let inputOutEl = document.querySelectorAll('input[name="out"]')
      let inputInEl = document.querySelectorAll('input[name="in"]')

      if (this.loading === 'Y') {
        sMsgCd = ''
        // 승인 내역 조회하는 데이터 중 freetime 날짜 비교 후 리턴
        demDetCatDayList.forEach((outItem, outIdx) => {
            inputOutEl.forEach(el => {
              if (el.disabled === false && outItem.cntrTypCd === el.id.substring(8) && this.$ekmtcCommon.isNotEmpty(el.value)) {
                  switch (el.id.substring(0, 3)) {
                    case 'dem':
                      if (Number(outItem.demMax) >= Number(el.value)) {
                        sMsgCd = 'dem'
                        msgDay = Number(el.value)
                        isOk = false
                      }
                    break

                    case 'det':
                      if (Number(outItem.detMax) >= Number(el.value)) {
                        sMsgCd = 'det'
                        msgDay = Number(el.value)
                        isOk = false
                      }
                    break

                    case 'cmb':
                      if (Number(outItem.cmbMax) >= Number(el.value)) {
                        sMsgCd = 'cmb'
                        msgDay = Number(el.value)
                        isOk = false
                      }
                    break

                    case 'mor':
                      if (Number(outItem.morMax) >= Number(el.value)) {
                        sMsgCd = 'mor'
                        msgDay = Number(el.value)
                        isOk = false
                      }
                    break

                    case 'str':
                      if (Number(outItem.strMax) >= Number(el.value)) {
                        sMsgCd = 'str'
                        msgDay = Number(el.value)
                        isOk = false
                      }
                    break
                  }
              }
            })
        })
        // msg = msgDay + ' 기본 freetime 기간 이하로 신청할 수 없습니다.'
        msg = this.$t('msg.SETT060P040.015') + ' ' + msgDay + this.$t('msg.SETT060P040.014')

        if (!isOk) {
          this.openAlert(msg)
        }
      }

      if (this.destination === 'Y') {
        console.log('this.destination ::: ', this.destination)
        sMsgCd = ''
        demDetCatDayList = this.cntrInList
        // 승인 내역 조회하는 데이터 중 freetime 날짜 비교 후 리턴
        demDetCatDayList.forEach((outItem, inIdx) => {
          inputInEl.forEach(el => {
            if (el.disabled === false && outItem.cntrTypCd === el.id.substring(7) && this.$ekmtcCommon.isNotEmpty(el.value)) {
                switch (el.id.substring(0, 3)) {
                  case 'dem':
                    if (Number(outItem.demMax) >= Number(el.value)) {
                      sMsgCd = 'dem'
                      msgDay = Number(el.value)
                      isOk = false
                    }
                  break

                  case 'det':
                    if (Number(outItem.detMax) >= Number(el.value)) {
                      sMsgCd = 'det'
                      msgDay = Number(el.value)
                      isOk = false
                    }
                  break

                  case 'cmb':
                    if (Number(outItem.cmbMax) >= Number(el.value)) {
                      sMsgCd = 'cmb'
                      msgDay = Number(el.value)
                      isOk = false
                    }
                  break

                  case 'mor':
                    if (Number(outItem.morMax) >= Number(el.value)) {
                      sMsgCd = 'mor'
                      msgDay = Number(el.value)
                      isOk = false
                    }
                  break

                  case 'str':
                    if (Number(outItem.strMax) >= Number(el.value)) {
                      sMsgCd = 'str'
                      msgDay = Number(el.value)
                      isOk = false
                    }
                  break
                }
            }
          })
        })

        // msg = msgDay + ' 기본 freetime 기간 이하로 신청할 수 없습니다.'
        msg = this.$t('msg.SETT060P040.015') + msgDay + this.$t('msg.SETT060P040.014')

        if (!isOk) {
          this.openAlert(msg)
        }
      }

      return isOk
    },
    searchApvData () {
      let aStr = ''
      let apvList = this.apvList

      let msgCatCd = ''
      let msgReqDds = ''
      let msg = ''

      for (var i = 0; i < apvList.length; i++) {
        aStr += apvList[i].blNo + ',' + apvList[i].cntrTypCd + ',' + apvList[i].demDetCatCd + ',' + apvList[i].eiCatCd + ':'
      }

      this.apvStr = aStr
      FreeTimeExtensReq.findFreetimeApvList(this.apvStr).then(response => {
        console.log('response :::: ', JSON.stringify(response))

        this.reqApvList = response.data.apvList

        // 승인 내역 조회하는 데이터 중 freetime 날짜 비교 후 리턴
        this.reqApvList.forEach((outItem, outIdx) => { // 12, 13
          msgCatCd = outItem.demDetCatCdNm
          msgReqDds = outItem.reqDds

          this.apvList.forEach((inItem, inIdx) => {
            if (outItem.demDetCatCd === inItem.demDetCatCd) {
              if (Number(outItem.reqDds) >= Number(inItem.reqDds)) {
                msg = this.$t('msg.SETT060P040.015') + msgReqDds + this.$t('msg.SETT060P040.014') + ' ' +
                //  일에 대한 승인 내역이 존재합니다. 해당일 이하로 Freetime을 신청할 수 없습니다.<br> 승인 날짜보다 길게 신청해주세요.
                this.openAlert(msg)
              } else {
                this.reqParams.apvCheck = 'Y'
                this.insertFreetime(this.reqParams)
              }
            }
          })
        })
        msgCatCd = ''
        msgReqDds = ''
      }).catch(err => {
        console.log(err)
      })
    },
    searchData () {
      // this.param.blNo = this.parentInfo.blNo //'INC3421106' //props blNo
      // this.param.eiCatCd = this.parentInfo.eiCatCd //'O' //props eiCatCd
      // this.param.sr = this.parentInfo.sr //'123' //props sr
      FreeTimeExtensReq.freeTimeExtensReqList(this.param).then(response => {
        // console.log('response.data=' + JSON.stringify(response.data))
        this.list = response.data
        this.rstList = this.list.rstList[0]

        if (this.parentInfo.eiCatCd === this.param.eiCatCd) {
            this.cntrList = this.list.rstList1
        }

        // console.log('rstList1=' + JSON.stringify(this.list.rstList1))
        // console.log('rstList=' + JSON.stringify(this.rstList))
        // console.log('rstArr=' + JSON.stringify(this.list.rstArr))
        // console.log('appReqInfoList=' + JSON.stringify(this.list.appReqInfoList))

        if (this.param.eiCatCd === 'O') {
          this.outAppRequestList = this.list.appReqInfoList
          this.outAppRequestListCnt = this.list.appReqInfoList.length
          this.cntrOutList = this.list.rstList1
        } else if (this.param.eiCatCd === 'I') {
          this.inAppRequestList = this.list.appReqInfoList
          this.inAppRequestListCnt = this.list.appReqInfoList.length
          this.cntrInList = this.list.rstList1
        }

        this.polPortEnm = this.rstList.polPortEnm
        this.podPortEnm = this.rstList.podPortEnm
        this.podCtrCd = this.rstList.podCtrCd

        this.polPortNm = this.rstList.polPortNm
        this.podPortNm = this.rstList.podPortNm
        // console.log('response.rstList=' + this.rstList)
        //console.log('response.crmList=' + JSON.stringify(this.list.crmList))
        //console.log('===> ' + JSON.stringify(this.list.rstArr))
        // this.originCheck() //발생지
        //console.log(' asDemDetCatCd== ' + this.send.asDemDetCatCd)

        if (this.rstList.polCtrCd === 'KR') {
          this.checkDem = this.checkDemDetMorRcv('01')
          this.checkDet = this.checkDemDetMorRcv('02')
          this.checkMor = this.checkDemDetMorRcv('04')

          this.parentInfo.tableArr.forEach(t => {
            if (t.id === 'MOR') {
              this.morCnt = t.payload.rows.length
            } else if (t.id === 'DEM') {
              let apvStsCd = t.payload.freeTimeInvoice.apvStsCd
              if (apvStsCd === '02' || apvStsCd === '03' || apvStsCd === '07') {
                this.checkDem = true
              }
            } else if (t.id === 'DET') {
              let apvStsCd = t.payload.freeTimeInvoice.apvStsCd
              if (apvStsCd === '02' || apvStsCd === '03' || apvStsCd === '07') {
                this.checkDet = true
              }
            }
          })

          if (this.checkSearh === false) {
            this.checkSearh = true

            if (this.checkDem && this.checkDet && (this.checkMor || this.morCnt === 0)) {
              this.loading = 'N'
              this.destination = 'Y'
              $('#chk1').attr('disabled', true)

              this.changeInOut('destination', this.destination)
            } else {
              $('#chk1').removeAttr('disabled', true)
            }
          }
        }
      }).catch(err => {
        console.log(err)
        //this.contents = 'xxxxxxxxxxxxxxxxxxxxx'
      })
      //console.log('searchData')
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        // onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.SETT060P040.012'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    moveProfile () {
      // 프로필 화면이동
      this.$router.push('/my-info/setting/profile').catch(() => {})
      window.app.$Simplert.close()
    },
    openConfirmAlert (msg, func) {
      let obj = {
        // onClose: this.closeFun,
        onConfirm: this.moveProfile,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$t('msg.SETT060P040.008'),
        customConfirmBtnText: this.$t('msg.SETT060P040.012'),
        useConfirmBtn: true,
        customConfirmBtnClass: 'button blue lg',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    autoArmService () {
      this.openConfirmAlert(
        this.$t('msg.SETT060P040.013')
        )
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
</style>
